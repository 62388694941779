<template>
  <v-col cols="12" aut-dynamic-seeddata-config>
    <APIDesigner
      :definition.sync="seedData.apis.data"
      type="data"
      @update:definition="updateDefinition"
    />
  </v-col>
</template>
<script>
import { defaultsDeep } from "lodash";
import { clone, rules } from "@/util.js";
import APIDesigner from "@/components/fields/Action/APIDesigner.vue";
const debug = require("debug")("atman.components.dynamic_seed");
debug(`dynamic_seed`);
export default {
  name: "DynamicSeed",
  props: {
    data: {
      type: Object,
    },
  },
  components: {
    APIDesigner,
  },
  data() {
    const seedData = defaultsDeep(clone(this.data), {
      apis: {
        data: {
          url: "",
          type: "get",
        },
      },
    });
    return {
      seedData,
      applicableRules: [rules.mandatory()],
    };
  },
  created() {
    debug(`In created of DynamicSeed`);
  },
  mounted() {
    debug(`In mounted of DynamicSeed`);
  },
  methods: {
    updateDefinition() {
      delete this.seedData.value;
      this.$emit("update:seed_data", this.seedData);
    },
  },
};
</script>
