<template>
  <v-col cols="12">
    <v-switch
      dense
      aut-edit-seeddata-toggle
      v-model="isDynamic"
      :label="dropdownTypeLabel()"
      persistent-hint
      hint="Choose whether this should be a static list or a dynamic one"
    ></v-switch>
    <DynamicSeed
      v-if="isDynamic"
      @update:seed_data="updateDefinition"
      :data="fieldDefinition.options"
    />
    <StaticSeed
      v-else
      @update:seed_data="updateDefinition"
      :data="fieldDefinition.options"
    />
  </v-col>
</template>
<script>
import { clone } from "@/util.js";
import StaticSeed from "./StaticSeed.vue";
import DynamicSeed from "./DynamicSeed.vue";
const debug = require("debug")("atman.components.seed_data_designer");
debug(`seed_data_designer`);
export default {
  name: "SeedDataDesigner",
  props: {
    definition: {
      type: Object,
    },
  },
  components: {
    StaticSeed,
    DynamicSeed,
  },
  data() {
    return {
      fieldDefinition: clone(this.definition),
      isDynamic: !!this?.definition?.options?.apis?.data?.url,
    };
  },
  created() {
    debug(`In created of SeedDataDesigner`);
  },
  mounted() {
    debug(`In mounted of SeedDataDesigner`);
  },
  methods: {
    dropdownTypeLabel() {
      return `Type: ${this.isDynamic ? "Dynamic" : "Static"}`;
    },
    updateDefinition(seedData) {
      debug(`In SeedDataDesigner`, seedData);
      this.fieldDefinition.options = seedData;
      this.$emit("update:definition", this.fieldDefinition);
    },
  },
};
</script>
